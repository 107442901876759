import Aos from "aos";
import React, { useEffect } from "react";
import "./styles.scss";
const LandingBlog = () => {
  return (
    <div className="landing-blog-container">
      <div className="container">
        <div className="landing-blog-heading">
          Blog Posts <span className="sec-header-line"></span>
        </div>
        <div className="landing-blog-subheading">
          Professional & reliable human resource data
        </div>
        <div className="landing-blog-body">
          <div className="long-grid" data-aos="fade-right">
            <div className="long-grid-image">
              <img
                src="https://agni.wpengine.com/wp-content/uploads/2022/07/blog7.webp"
                alt=""
              />
            </div>
            <div className="long-grid-body">
              <div className="grid-date">
                <i class="fa-solid fa-calendar-days"></i> July 4, 2022
              </div>
              <div className="grid-heading">
                blog7 July 4, 2022 Human Resource The Main Backbone{" "}
              </div>
              <div className="grid-body">
                Rorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis
                ipsum suspendisse ultrices gravida....
              </div>
              <div className="grid-bottom">Read More</div>
            </div>
          </div>
          <div className="small-grids-container">
            <div className="small-grid" data-aos="fade-left">
              <div className="small-grid-image">
                <img
                  src="https://agni.wpengine.com/wp-content/uploads/2022/07/blog8.webp"
                  alt=""
                />
                <div className="grid-date">
                  <i class="fa-solid fa-calendar-days"></i> July 4, 2022
                </div>
              </div>
              <div className="grid-heading">
                Team Buildup For Better Projects
              </div>
            </div>

            <div className="small-grid" data-aos="fade-left">
              <div className="small-grid-image">
                <img
                  src="https://agni.wpengine.com/wp-content/uploads/2022/07/blog8.webp"
                  alt=""
                />
                <div className="grid-date">
                  <i class="fa-solid fa-calendar-days"></i> July 4, 2022
                </div>
              </div>
              <div className="grid-heading">
                Team Buildup For Better Projects
              </div>
            </div>
            {/* <div className="small-grid">
              <div className="small-grid-image">
                <img
                  src="https://agni.wpengine.com/wp-content/uploads/2022/07/blog8.webp"
                  alt=""
                />
                <div className="grid-date">
                  <i class="fa-solid fa-calendar-days"></i> July 4, 2022
                </div>
              </div>
              <div className="grid-heading">
                Team Buildup For Better Projects
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingBlog;
