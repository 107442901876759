import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-tabs/style/react-tabs.css";
import AOS from "aos";
import Landing from "./Pages/Landing/Landing";
import PreloaderContainer from "./Components/PreloaderContainer/PreloaderContainer";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    AOS.init();
    setTimeout(() => {
      AOS.refresh();
    }, 500);
  }, []);
  return (
    <div className="App">
      <PreloaderContainer>
        <Landing />
      </PreloaderContainer>
    </div>
  );
}

export default App;
