import React, { useEffect, useState } from "react";
import Preloader from "../Preloader/Preloader";
import './styles.scss'
const PreloaderContainer = (props) => {
  const [preloaderOff, setPreloaderOff] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setPreloaderOff(true);
    }, 3200);
  }, []);
  return (
    <div className="preloader-container">
      <div className={`preloader-body ${preloaderOff ? "preloader-off" : ""}`}>
        <Preloader />
      </div>
      <div className="remain-body">{props.children}</div>
    </div>
  );
};

export default PreloaderContainer;
