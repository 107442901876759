import React, { useEffect } from "react";
import "./styles.scss";
import Play from "../../Assets/Images/Brand/play.png";
import Aos from "aos";
const LandingVideo = () => {
  return (
    <div className="landing-video-container" id="about">
      <div className="landing-video-play" data-aos="fade-down">
        <div className="play-inner">
          <div className="play-spacer"></div>
          {/* <img src={Play} alt="" /> */}
        </div>
      </div>
      <div className="landing-video-heading" data-aos="fade-up">
        Want to create <br /> something beautiful
      </div>
      <div className="landing-video-bottom" data-aos="fade-up">
        <span>
          New Business{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 59"
            // style="enable-background:new 0 0 100 59;"
            // xml:space="preserve"
          >
            <polygon points="59.9,6.1 79.4,25.7 6,25.7 6,33.3 79.4,33.3 59.9,52.9 65.3,58.2 94,29.5 65.3,0.8 "></polygon>
          </svg>
        </span>{" "}
        <span>
          Inquiry
          <svg
            xmlns="http://www.w3.org/2000/svg"
            // xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 59"
            // style="enable-background:new 0 0 100 59;"
            // xml:space="preserve"
          >
            <polygon points="59.9,6.1 79.4,25.7 6,25.7 6,33.3 79.4,33.3 59.9,52.9 65.3,58.2 94,29.5 65.3,0.8 "></polygon>
          </svg>
        </span>
      </div>
    </div>
  );
};

export default LandingVideo;
