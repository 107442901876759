import React, { useState } from "react";
import "./styles.scss";
const Nav = () => {
  const [selectedNav, setSelectedNav] = useState("home");
  const navLinks = [
    {
      id: "home",
      name: "Home",
      link: "/home",
    },
    {
      id: "about",
      name: "About",
      link: "/home",
    },
    {
      id: "services",
      name: "Services",
      link: "/services",
    },
    {
      id: "contact",
      name: "Contact",
      link: "/contact",
    },
  ];
  const handleNavClick = (e) => {
    setSelectedNav(e.id);
  };
  return (
    <div className="nav-container">
      <div className="row" style={{ height: "100%" }}>
        <div className="col-5">
          <div className="nav-left">
            {navLinks.map((navItem, index) => {
              return (
                <div
                  key={index}
                  className="single-nav-item"
                  onClick={() => handleNavClick(navItem)}
                >
                  <a href={`#${navItem.id}`}>{navItem.name}</a>
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-7">
          <div className="nav-right">
            <div className="nav-right-contact">
              <div className="nav-right-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  //   xmlns:xlink="http://www.w3.org/1999/xlink"
                  id="Layer_1"
                  x="0px"
                  y="0px"
                  viewBox="0 0 100 100"
                  //   style="enable-background:new 0 0 100 100;"
                  //   xml:space="preserve"
                >
                  <path d="M34,42.5c1.9,4.5,5,9.3,8.9,14.1c3.4,3.9,7.4,7.7,12,10.7c0.8,0.5,1.6,0.9,2.3,1.1c0.3,0,0.5-0.1,0.8-0.3l8.1-6.9 c1.5-1.2,3.2-1.9,5-1.8c1.8,0,3.5,0.7,4.9,2c2.6,2.4,5.3,4.9,8,7.2c2.7,2.4,5.5,4.6,8.2,6.8c0.9,0.7,1.6,1.5,2.2,2.6 c0.4,0.9,0.7,2,0.7,3.1s-0.1,2.2-0.5,3.2c-0.5,0.9-1.2,1.8-2,2.6l-11.9,10C79,98.2,77,99.1,75,99.5c-2,0.3-4.2,0.1-6.2-0.7 c-8-3-15.5-7-22.6-12c-6.9-4.9-13.2-10.7-18.9-17.4c-5.7-6.6-10.4-13.9-14.1-21.5c-3.8-7.7-6.5-15.9-8.1-24.2 c-0.4-2.2-0.3-4.3,0.4-6.4c0.7-1.9,1.9-3.6,3.6-5.1L21.1,2.1c0.8-0.7,1.9-1.2,2.8-1.5H24c1.1-0.3,2.2-0.3,3.1-0.1 c1.2,0.3,2.2,0.7,3,1.4c0.9,0.5,1.6,1.5,2.2,2.4c1.6,3.1,3.4,6.2,5.3,9.3c1.9,3.1,3.8,6.1,5.8,9.1c1.1,1.6,1.4,3.4,1.2,5.1 c-0.3,1.8-1.2,3.4-2.6,4.6l-8.2,6.9c-0.3,0.3-0.4,0.5-0.4,0.8C33.4,40.7,33.6,41.7,34,42.5L34,42.5z M38.8,60.1 c-4.3-5-7.6-10.4-9.9-15.3c-0.7-1.6-1.2-3.4-1.1-5.1c0.1-1.8,0.8-3.2,2.4-4.6l8.1-6.9c0.4-0.3,0.5-0.7,0.7-1.2c0-0.4,0-0.8-0.3-1.2 c-2.2-3.1-4.1-6.2-5.9-9.3c-1.9-3.1-3.6-6.4-5.4-9.6c-0.1-0.1-0.3-0.4-0.5-0.5C26.7,6.1,26.5,6,26.2,6c-0.3-0.1-0.5-0.1-0.8,0l0,0 c-0.3,0-0.5,0.1-0.7,0.4l-12,10c-0.9,0.8-1.6,1.8-1.9,2.8c-0.4,1.1-0.4,2.2-0.3,3.4c1.6,7.8,4.2,15.5,7.7,22.8 c3.5,7.2,8,14.1,13.2,20.4c5.4,6.4,11.5,11.8,18,16.4c6.6,4.7,13.8,8.5,21.2,11.5c1.2,0.4,2.3,0.5,3.4,0.3c1.1-0.1,2.2-0.7,3.1-1.5 l11.8-10c0.3-0.1,0.4-0.4,0.5-0.5c0.1-0.3,0.1-0.5,0.1-0.8c0-0.3,0-0.5-0.1-0.8c-0.1-0.3-0.3-0.4-0.5-0.5c-2.8-2.3-5.7-4.6-8.5-7 c-2.7-2.3-5.5-4.9-8.2-7.3c-0.3-0.4-0.7-0.5-1.1-0.5c-0.5,0-0.9,0.1-1.2,0.4l-8.2,6.9c-1.5,1.2-3.1,1.8-4.9,1.6 c-1.6-0.1-3.4-0.9-4.9-1.9C46.9,68.6,42.4,64.5,38.8,60.1L38.8,60.1z"></path>
                </svg>
              </div>
              <div className="nav-right-contact-text">
                <div className="top">Have any question?</div>
                <div className="bottom">+91 9874563210</div>
              </div>
            </div>
            <div className="nav-right-button">
              <button className="main-button">Start Project</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
