import React from "react";
import "./styles.scss";
import loader from "../../Assets/Images/Brand/preloader.gif";
const Preloader = () => {
  return (
    <div className="preloader">
      <img src={loader} alt="" />
    </div>
  );
};

export default Preloader;
