import React from "react";
import boxData from "../../Assets/DummyData/BoxData";
import "./styles.scss";
const LandingAbout = () => {
  return (
    <div className="landing-about-container" id="services">
      <div className="container">
        <div className="landing-about-heading">
          <span className="heading-gradient">Employee </span>friendly service
        </div>
        <div className="landing-about-sub-heading">
          We provide best management, Payroll & Worksite Safety Service
        </div>
        <div className="landing-about-body row">
          {boxData.map((boxD, index) => {
            return (
              <div className="col--2 col-md-4 col-lg-4">
                <div
                  className="landing-body-box"
                  data-aos="zoom-in"
                  data-aos-delay={index * 100}
                  key={index}
                >
                  <div className="landing-body-box-icon">{boxD.icon}</div>
                  <div className="landing-body-box-heading">{boxD.heading}</div>
                  <div className="landing-body-box-text">{boxD.text}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LandingAbout;
