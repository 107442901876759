import React from "react";
import "./styles.scss";
const LandingAffiliation = () => {
  const affiliationData = [
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand1-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand2-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand3-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand4-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand5-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand6-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand7-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand8-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand9-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand10-logo.png",
    },
    {
      link: "",
      name: "",
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/08/brand11-logo.png",
    },
  ];
  return (
    <div className="landing-affiliation-container">
      <div className="container">
        <div className="landing-affiliation-heading">Our Affiliations <span className="sec-header-line"></span></div>
        <div className="landing-affiliation-sub-heading">
          Professional & reliable partners
        </div>

        <div className="landing-affiliation-body">
          {affiliationData.map((aff, index) => {
            return (
              <div className="landing-affiliation-item" key={index}>
                <img src={aff.image} alt="" />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LandingAffiliation;
