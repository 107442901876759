import React from "react";
import Nav from "../Nav/Nav";
import Logo from "../../Assets/Images/Brand/logoHorizontal.png";
import "./styles.scss";
const Header = () => {
  return (
    <div className="header-container">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-4">
            <div className="header-logo">
              <img src={Logo} alt="" />
            </div>
          </div>
          <div className="col-lg-8">
            <Nav />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
