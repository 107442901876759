import React from "react";
import "./styles.scss";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const LandingWorks = () => {
  const workData = [
    {
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/07/Web-Development.webp",
      name: "Web Development",
      detail: "Sullam mollis vehicula odio sed pretium.",
    },
    {
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/07/Website-Branding.webp",
      name: "Website Branding",
      detail: "Sullam mollis vehicula odio sed pretium.",
    },
    {
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/07/Online-Marketing.webp",
      name: "Online Marketing",
      detail: "Sullam mollis vehicula odio sed pretium.",
    },
    {
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/07/Social-Promotion.webp",
      name: "Social Promotion",
      detail: "Sullam mollis vehicula odio sed pretium.",
    },
    {
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/07/Growth-base-Marketing.webp",
      name: "Growth Base Marketing",
      detail: "Sullam mollis vehicula odio sed pretium.",
    },
    {
      image:
        "https://agni.wpengine.com/wp-content/uploads/2022/07/Content-Marketing.webp",
      name: "Content Marketing",
      detail: "Sullam mollis vehicula odio sed pretium.",
    },
  ];
  return (
    <div className="landing-works-container">
      <div className="container">
        <div className="landing-works-heading">
          View Recent Works <span className="sec-header-line"></span>
        </div>
        <div className="landing-works-subheading">
          Professional & reliable human resource data
        </div>
        <div className="landing-works-body">
          <div className="landing-works-tab-container">
            {workData.map((wd, index) => {
              return (
                <div
                  className="wd-image-container"
                  data-aos="fade-left"
                  data-aos-delay={index * 200}
                  key={index}
                >
                  <img src={wd.image} alt="" />
                  <div className="wd-detail">
                    <div className="wd-heading">{wd.name}</div>
                    <div className="wd-info">{wd.detail}</div>
                  </div>
                  <div className="wd-overlay"></div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingWorks;
