import React from "react";
import Footer from "../../Components/Footer/Footer";
import Header from "../../Components/Header/Header";
import LandingAbout from "../../Components/LandingAbout/LandingAbout";
import LandingAffiliation from "../../Components/LandingAffiliation/LandingAffiliation";
import LandingBlog from "../../Components/LandingBlog/LandingBlog";
import LandingTeam from "../../Components/LandingTeam/LandingTeam";
import LandingVideo from "../../Components/LandingVideo/LandingVideo";
import LandingWorks from "../../Components/LandingWorks/LandingWorks";
import { Parallax, Background } from "react-parallax";
import "./styles.scss";
const Landing = () => {
  return (
    <div className="landing-page-container">
      <div className="landing-upper">
        <div className="landing-page-header">
          <Header />
        </div>
        <div className="landing-hero">
          <div className="landing-hero-box">
            <div className="landing-hero-inner-box">
              <div className="over-line"></div>
              <div className="landing-hero-box-text">
                Creative <br /> Job <br /> Solution
              </div>
              <div className="landing-hero-button">
                <button className="alternate-button">Read More</button>
              </div>
            </div>
          </div>

          <div
            className="landing-hero-image"
            style={{
              flexBasis: "25%",
            }}
          >
            <Parallax
              strength={200}
              renderLayer={(percentage) => (
                <div
                  className="parallax-inner-div"
                  style={{
                    transform: `translate(0%, ${
                      (percentage - 0.847561) * 15
                    }%)`,

                    backgroundImage: `url(https://agni.wpengine.com/wp-content/uploads/2022/07/slide-layer1a.webp)`,
                  }}
                />
              )}
            ></Parallax>
          </div>
          <div className="landing-hero-image" style={{ flexBasis: "15%" }}>
            <Parallax
              strength={200}
              renderLayer={(percentage) => (
                <div
                  className="parallax-inner-div"
                  style={{
                    transform: `translate(0%, -${
                      (percentage - 0.847561) * 15
                    }%)`,

                    backgroundImage: `url(https://agni.wpengine.com/wp-content/uploads/2022/07/slide-layer1b.webp)`,
                  }}
                />
              )}
            ></Parallax>
          </div>
          <div className="landing-hero-image" style={{ flexBasis: "20%" }}>
            <Parallax
              strength={200}
              renderLayer={(percentage) => (
                <div
                  className="parallax-inner-div"
                  style={{
                    transform: `translate(0%, ${
                      (percentage - 0.847561) * 15
                    }%)`,

                    backgroundImage: `url(https://agni.wpengine.com/wp-content/uploads/2022/07/slide-layer1c.webp)`,
                  }}
                />
              )}
            ></Parallax>
          </div>
          <div className="landing-hero-image" style={{ flexBasis: "40%" }}>
            <Parallax
              strength={200}
              renderLayer={(percentage) => (
                <div
                  className="parallax-inner-div"
                  style={{
                    transform: `translate(0%, -${
                      (percentage - 0.847561) * 15
                    }%)`,

                    backgroundImage: `url(https://agni.wpengine.com/wp-content/uploads/2022/07/slide-layer1d.webp)`,
                  }}
                />
              )}
            ></Parallax>
          </div>
        </div>
        <LandingAbout />
      </div>
      <LandingVideo />
      <LandingWorks />
      <div className="bottom-edge">
        <LandingTeam />
        <LandingBlog />
        <LandingAffiliation />
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
