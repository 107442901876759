import React from "react";
import "./styles.scss";
const LandingTeam = () => {
  const teamData = [
    {
      name: "Valan Roy",
      role: "Sr. Consultant",
      image: "https://agni.wpengine.com/wp-content/uploads/2022/08/team10.jpg",
    },
    {
      name: "Amalan Jose",
      role: "Chief Advisor",
      image: "https://agni.wpengine.com/wp-content/uploads/2022/08/team7.jpg",
    },
    {
      name: "Maria Jose",
      role: "HR Manager",
      image: "https://agni.wpengine.com/wp-content/uploads/2022/08/team9.jpg",
    },
    {
      name: "Jennifer Roy",
      role: "Creative Head",
      image: "https://agni.wpengine.com/wp-content/uploads/2022/08/team11.jpg",
    },
  ];
  return (
    <div className="landing-team-contrainer">
      <div className="container">
        <div className="landing-team-heading">
          Our Team Members <span className="sec-header-line"></span>
        </div>
        <div className="landing-team-subheading">
          Our Professional Placement & Educational Consultants
        </div>
        <div className="landing-team-body row">
          {teamData.map((td, index) => {
            return (
              <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                <div
                  className="landing-team-box"
                  data-aos="fade-up"
                  data-aos-delay={index * 250}
                >
                  <img src={td.image} alt="" />
                  <div className="landing-team-name">{td.name}</div>
                  <div className="landing-team-role">{td.role}</div>
                  <div className="landing-team-bottom-line"></div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LandingTeam;
